import React from 'react'
import { Box, Text } from '@chakra-ui/layout'
import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import { CircularProgress } from '@chakra-ui/react'

type Props = {
  fileName: string;
  status: 'loading' | 'success' | 'error';
  error?: string;
};

export const UploadFileItem = ({ fileName, status, error }: Props) => {
  function getBorderColorByStatus () {
    const colorsByStatus = {
      error: 'red.500',
      success: 'green.500',
      loading: 'gray.200'
    }

    return colorsByStatus[status]
  }

  function renderStatus () {
    if (status === 'success') {
      return (
        <Box display="flex" alignItems="center" color="green.600" marginTop={1}>
          <CheckIcon marginRight={2} />
          <Text fontWeight="500">Sucesso</Text>
        </Box>
      )
    }

    if (status === 'error') {
      return (
        <Box display="flex" alignItems="center" color="red.600" marginTop={1}>
          <CloseIcon marginRight={2} />
          <Box lineHeight={1}>
            <Text fontWeight="500">Erro</Text>
            <Text>{error}</Text>
          </Box>
        </Box>
      )
    }

    return (
      <Box display="flex" alignItems="center" color="gray.600" marginTop={1}>
        <CircularProgress isIndeterminate color="gray.600" marginRight={2} size="24px" />
        <Text fontWeight="500">Enviando...</Text>
      </Box>
    )
  }

  return (
    <Box borderWidth={2} borderColor={getBorderColorByStatus()} borderRadius={12} padding={4}>
      <Text fontSize={18} fontWeight="500" marginBottom={1}>
        {fileName}
      </Text>
      {renderStatus()}
    </Box>
  )
}
