import axios from 'axios'
import { API_URL, AUTH_COOKIE_NAME } from '../config'
import { cookies } from '../helpers'

export const getInstance = (emptyBaseUrl = false) => {
  const headers: { Authorization?: string } = {}
  const token = cookies.get(AUTH_COOKIE_NAME)
  if (token) headers.Authorization = `Bearer ${token}`
  return axios.create({ baseURL: !emptyBaseUrl ? API_URL : undefined, headers })
}
