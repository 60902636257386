import React from 'react'
import { Box } from '@chakra-ui/react'
import { UploadDropzone, UploadFileItem } from '../components'
import { Page } from '../components'
import { getUniqueId } from '../helpers'
import { uploadFile } from '../api'
import { UploadedFilesAction, UploadedFilesActionType, uploadedFilesReducer, UploadedFilesState } from '../store'
import { UploadedFile } from '../models'

export const UploadPage = () => {
  const [loading] = React.useState<boolean>(false)
  const [{ uploadedFiles }, dispatchUploadedfiles] = React.useReducer<React.Reducer<UploadedFilesState, UploadedFilesAction>>(
    uploadedFilesReducer,
    { uploadedFiles: [] }
  )

  const handleFilesSelected = React.useCallback(
    (files: File[]) => {
      if (!files.length) return
      files.forEach((file) => handleUpload(file))
    },
    [uploadedFiles]
  )

  function handleUpload (file: File) {
    const uploadedFile: UploadedFile = { id: getUniqueId(), file, status: 'loading' }
    dispatchUploadedfiles({ type: UploadedFilesActionType.ADD, payload: uploadedFile })

    uploadFile(file)
      .then(() => dispatchUploadedfiles({ type: UploadedFilesActionType.SET_SUCCESS, payload: uploadedFile }))
      .catch((error) => {
        const errorDescription = error?.response?.data?.error || error.toString()
        dispatchUploadedfiles({ type: UploadedFilesActionType.SET_ERROR, payload: uploadedFile, errorDescription })
      })
  }

  function renderFiles () {
    return (
      <Box display="grid" marginTop={6} gridTemplateColumns="1fr 1fr" gridGap={4}>
        {uploadedFiles.map((uploadedFile) => (
          <UploadFileItem
            key={uploadedFile.id}
            fileName={uploadedFile.file.name}
            status={uploadedFile.status}
            error={uploadedFile.errorDescription}
          />
        ))}
      </Box>
    )
  }

  return (
    <Page title="Enviar arquivos" activePage="upload">
      <UploadDropzone onFilesChanged={handleFilesSelected} disabled={loading} />
      {renderFiles()}
    </Page>
  )
}
