import React from 'react'
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table'
import { DeleteIcon, DownloadIcon } from '@chakra-ui/icons'
import { Button, IconButton } from '@chakra-ui/button'
import { useToast } from '@chakra-ui/toast'
import { downloadFile, fetchFiles, removeFile } from '../api'
import { Page } from '../components'
import { File } from '../models'
import { formatToString } from '../helpers'

export const FilesPage = () => {
  const toast = useToast()
  const [files, setFiles] = React.useState<File[]>([])
  const [downloadingFile, setDownloadingFile] = React.useState<string | undefined>()

  React.useEffect(() => {
    fetchFiles()
      .then((data) => setFiles(data))
      .catch((error) => {
        console.error(error)
        toast({
          title: 'Erro',
          description: `Erro ao obter os arquivos: ${error.message}`,
          status: 'error',
          position: 'top-right'
        })
      })
  }, [])

  const download = React.useCallback((fileUrl: string, fileName: string) => {
    setDownloadingFile(fileName)
    downloadFile(fileUrl)
      .then((contents) => {
        const blobContent = window.URL.createObjectURL(new Blob([contents]))
        const shadowAnchor = document.createElement('a')
        shadowAnchor.href = blobContent
        shadowAnchor.setAttribute('download', fileName)
        document.body.appendChild(shadowAnchor)
        shadowAnchor.click()
        setDownloadingFile(undefined)
      })
      .catch((error) => {
        setDownloadingFile(undefined)
        console.error(error)
      })
  }, [])

  const remove = React.useCallback((fileName: string) => {
    setDownloadingFile(fileName)
    removeFile(fileName)
      .then(() => {
        setDownloadingFile(undefined)
        toast({
          title: 'Arquivo removido',
          description: 'O arquivo foi removido com sucesso',
          status: 'success',
          position: 'top-right'
        })
        setFiles((files) => files.filter((file) => file.file.name !== fileName))
      })
      .catch((error) => {
        setDownloadingFile(undefined)
        toast({
          title: 'Erro',
          description: `Erro ao excluir o arquivo: ${error.message}`,
          status: 'error',
          position: 'top-right'
        })
        console.error(error)
      })
  }, [])

  function getFormattedDate (date: string) {
    return formatToString(new Date(date))
  }

  return (
    <Page title="Arquivos" activePage="files">
      <Table>
        <Thead>
          <Th>Nome do arquivo</Th>
          <Th>Data de criação</Th>
          <Th>Ações</Th>
        </Thead>
        <Tbody>
          {files.map((file) => (
            <Tr key={file.file.name}>
              <Td>{file.file.name}</Td>
              <Td>{getFormattedDate(file.creationDate)}</Td>
              <Td>
                <Button
                  colorScheme="blue"
                  size="sm"
                  leftIcon={<DownloadIcon />}
                  mr={2}
                  onClick={() => download(file.file.downloadUrl, file.file.name)}
                  disabled={downloadingFile === file.file.name}
                >
                  Arquivo
                </Button>
                <Button
                  colorScheme="blue"
                  size="sm"
                  leftIcon={<DownloadIcon />}
                  mr={2}
                  onClick={() => download(file.checksum.downloadUrl, file.checksum.name)}
                >
                  Checksum
                </Button>
                <IconButton
                  aria-label="Remover arquivo"
                  colorScheme="red"
                  size="sm"
                  icon={<DeleteIcon />}
                  onClick={() => {
                    if (!confirm('Deseja realmente remover o arquivo?')) return
                    remove(file.file.name)
                  }}
                ></IconButton>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Page>
  )
}
