import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { PrivateRoute } from './components'
import { LoginPage } from './pages/Login'
import { UploadPage } from './pages/Upload'
import { LogoutPage } from './pages/Logout'
import { FilesPage } from './pages/Files'

export const App = () => {
  const history = createBrowserHistory()

  return (
    <Router history={history}>
      <Switch>
        <Route path="/login" component={LoginPage} />
        <PrivateRoute path="/" exact component={UploadPage} />
        <PrivateRoute path="/files" component={FilesPage} />
        <PrivateRoute path="/logout" component={LogoutPage} />
      </Switch>
    </Router>
  )
}
