import { getInstance } from './config'

export interface TokenResponse {
  tokenType: string;
  accessToken: string;
  expiresIn: number;
}

export const getUserToken = async (payload: { email: string; password: string }): Promise<TokenResponse> => {
  const { data } = await getInstance().post('/token', payload)
  return data
}
