import React from 'react'
import { Box, Spinner } from '@chakra-ui/react'

export const PageLoading = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" paddingY="40px">
      <Spinner size="lg" color="blue.500" />
    </Box>
  )
}
