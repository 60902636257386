import { getInstance } from './config'
import { File as EncryptedFile } from '../models'

export const uploadFile = async (file: File): Promise<{ success: boolean }> => {
  const payload = new FormData()
  payload.append('file', file)
  const { data } = await getInstance().post('/upload', payload)
  return data
}

export const fetchFiles = async (): Promise<EncryptedFile[]> => {
  const { data } = await getInstance().get<EncryptedFile[]>('/files')
  return data
}

export const downloadFile = async (fileUrl: string): Promise<any> => {
  const { data } = await getInstance(true).get(fileUrl, {
    responseType: 'blob'
  })
  return data
}

export const removeFile = async (fileName: string): Promise<any> => {
  const { data } = await getInstance().delete('/files', { params: { fileName } })
  return data
}
