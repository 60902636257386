import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AUTH_COOKIE_NAME } from '../config'
import { cookies } from '../helpers'

type Props = {
  component: any
  [key: string]: any
}

export const PrivateRoute = ({ component: Component, ...rest }: Props) => {
  const isAuthenticated = cookies.get(AUTH_COOKIE_NAME)

  return (
    <Route {...rest} render={(props) => (
      isAuthenticated
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/login' }} />
    )} />
  )
}
