import React from 'react'
import { useDropzone } from 'react-dropzone'
import { Box, Text } from '@chakra-ui/react'

type Props = {
  onFilesChanged: (files: File[]) => void;
  disabled: boolean;
};

export const UploadDropzone = ({ onFilesChanged, disabled }: Props) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ multiple: true, disabled })

  React.useEffect(() => {
    onFilesChanged(acceptedFiles)
  }, [acceptedFiles])

  return (
    <Box>
      <Box
        cursor={disabled ? 'not-allowed' : 'pointer'}
        opacity={disabled ? 0.5 : 1}
        _hover={!disabled ? { backgroundColor: 'blue.50' } : {}}
        transition="0.1s all ease-in"
        borderColor="blue.600"
        borderStyle="dashed"
        borderWidth={3}
        paddingX={4}
        paddingY={10}
        borderRadius={12}
        textAlign="center"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Text fontSize={16} fontWeight={600}>
          Arraste e solte ou clique aqui para selecionar arquivos
        </Text>
      </Box>
    </Box>
  )
}
