import React, { FormEvent } from 'react'
import { Box, Button, Container, Flex, FormControl, FormLabel, Heading, Input, Stack, useToast } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { addHours, cookies } from '../helpers'
import { AUTH_COOKIE_NAME } from '../config'
import { getUserToken } from '../api'

export const LoginPage = () => {
  const toast = useToast()
  const history = useHistory()
  const [loading, setLoading] = React.useState<boolean>(false)

  const handleSubmit = React.useCallback(
    async (event: FormEvent) => {
      event.preventDefault()
      const target = event.target as typeof event.target & { email: { value: string }; password: { value: string } }
      const email = target.email.value
      const password = target.password.value

      setLoading(true)

      try {
        const { accessToken } = await getUserToken({ email, password })
        const expirationDate = addHours(new Date(), 3)
        cookies.set(AUTH_COOKIE_NAME, accessToken, { expires: expirationDate })
        history.push('/')
      } catch (error) {
        console.error(error)
        const message = 'Erro ao fazer login, tente novamente'
        toast({ title: 'Ops!', description: message, status: 'error' })
        setLoading(false)
      }
    },
    [loading]
  )

  return (
    <Container maxW="container.xl">
      <Flex minH="100vh" align="center" justify="center">
        <Stack spacing={8} mx="auto" maxW="lg" py={12} px={6}>
          <Stack align="center">
            <Heading fontSize="4xl">Login</Heading>
          </Stack>
          <Box rounded="lg" p={4} minW="400px">
            <form onSubmit={handleSubmit}>
              <Stack spacing={4}>
                <FormControl id="email">
                  <FormLabel>Endereço de e-mail</FormLabel>
                  <Input type="email" name="email" placeholder="Endereço de e-mail" required />
                </FormControl>
                <FormControl id="password">
                  <FormLabel>Senha</FormLabel>
                  <Input type="password" name="password" placeholder="Senha" required />
                </FormControl>
                <Button type="submit" bg="blue.400" color="white" _hover={{ bg: 'blue.500' }} disabled={loading} isLoading={loading}>
                  Entrar
                </Button>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Flex>
    </Container>
  )
}
