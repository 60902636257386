import React, { useEffect } from 'react'
import { Box } from '@chakra-ui/layout'
import { PageLoading } from '../components'
import { cookies } from '../helpers'
import { useHistory } from 'react-router'

export const LogoutPage = () => {
  const history = useHistory()

  useEffect(() => {
    setTimeout(() => {
      cookies.removeAll()
      history.push('/login')
    }, 1500)
  })

  return (
    <Box padding="40px">
      <PageLoading />
    </Box>
  )
}
