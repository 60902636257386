import React from 'react'
import { Container, Box, Flex, Text, Button, Stack, Link } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

type Props = {
  activePage?: string;
};

export const Header = ({ activePage }: Props) => {
  return (
    <Container maxW="container.xl" mb="40px">
      <Box>
        <Flex
          bg="white"
          color="gray.600"
          minH="60px"
          py={{ base: 2 }}
          px={{ base: 4 }}
          borderBottom={1}
          borderStyle="solid"
          borderColor="gray.200"
          align="center"
        >
          <Flex flex={1} justify="start">
            <Text fontFamily="heading" color="gray.800">
              SIJUD Encrypt
            </Text>
            <Flex display="flex" ml={10}>
              <Stack direction="row" spacing={4}>
              <Box>
                  <Link
                    to="/"
                    as={RouterLink}
                    p={2}
                    fontWeight={500}
                    color={activePage === 'upload' ? 'blue.400' : 'gray.600'}
                    _hover={{ textDecoration: 'none', color: 'gray.800' }}
                  >
                    Upload
                  </Link>
                </Box>
                <Box>
                  <Link
                    to="/files"
                    as={RouterLink}
                    p={2}
                    fontWeight={500}
                    color={activePage === 'files' ? 'blue.400' : 'gray.600'}
                    _hover={{ textDecoration: 'none', color: 'gray.800' }}
                  >
                    Arquivos
                  </Link>
                </Box>
              </Stack>
            </Flex>
          </Flex>
          <Stack flex={1} justify="flex-end" direction="row" spacing={6}>
            <Button to="/logout" as={RouterLink} display="inline-flex" fontWeight={600} colorScheme="blue" variant="ghost">
              Sair
            </Button>
          </Stack>
        </Flex>
      </Box>
    </Container>
  )
}
