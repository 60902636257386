import { addHours as _addHours, format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'

export const addHours = (date: Date, amount: number): Date => {
  return _addHours(date, amount)
}

export const formatToString = (date: Date): string => {
  return format(date, 'dd/MM/yyyy \'às\' hh:mm:ss', {
    locale: ptBR
  })
}
