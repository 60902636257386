import React from 'react'
import { Box, Container, Heading } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/button'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import { Header } from '.'
import { useHistory } from 'react-router'

type Props = {
  title: string;
  children: React.ReactNode;
  hasBackButton?: boolean;
  actionButton?: React.ReactNode;
  activePage?: string;
};

export const Page = (props: Props) => {
  const history = useHistory()

  function handleBack () {
    history.goBack()
  }

  return (
    <>
      <Header activePage={props.activePage} />
      <Container maxW="container.xl">
        {props.hasBackButton && (
          <Box>
            <Button
              variant="ghost"
              mr={2}
              paddingX="10px"
              marginLeft="-10px"
              leftIcon={<ChevronLeftIcon />}
              size="xs"
              height="36px"
              onClick={() => handleBack()}
            >
              Voltar
            </Button>
          </Box>
        )}
        <Box mb="40px" display="flex" alignItems="center">
          <Heading as="h1" size="lg">
            {props.title}
          </Heading>
          {props.actionButton && <Box marginLeft="auto">{props.actionButton}</Box>}
        </Box>
        <Box>{props.children}</Box>
      </Container>
    </>
  )
}
