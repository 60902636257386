/* eslint-disable no-unused-vars */
import { UploadedFile } from '../models'

export enum UploadedFilesActionType {
  ADD = 'ADD',
  SET_SUCCESS = 'SET_SUCCESS',
  SET_ERROR = 'SET_ERROR',
}

export interface UploadedFilesState {
  uploadedFiles: UploadedFile[];
}

export interface UploadedFilesAction {
  type: UploadedFilesActionType;
  payload: UploadedFile;
  errorDescription?: string;
}

export function uploadedFilesReducer (state: UploadedFilesState, action: UploadedFilesAction) {
  const { type, payload, errorDescription } = action

  switch (type) {
    case UploadedFilesActionType.ADD:
      return { uploadedFiles: [...state.uploadedFiles, payload] }
    case UploadedFilesActionType.SET_SUCCESS:
      return {
        uploadedFiles: state.uploadedFiles.map((item) => {
          if (item.id !== payload.id) return item
          return { ...item, status: 'success' } as UploadedFile
        })
      }
    case UploadedFilesActionType.SET_ERROR:
      return {
        uploadedFiles: state.uploadedFiles.map((item) => {
          if (item.id !== payload.id) return item
          return { ...item, status: 'error', errorDescription } as UploadedFile
        })
      }
  }
}
